import {
  Box, Button, Paper, TableContainer,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Loading, OffenseTeamMatchStats, DefenseTeamMatchStats } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { csvDownloadHandler } from 'shared/helpers/csvDownloadHandler';
import { useFetchMatchStatsQuery } from 'shared/services';

export function MatchStatsTable() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const [match] = useOutletContext();
  const {
    isLoading,
    isFetching,
    data: stats,
  } = useFetchMatchStatsQuery({ team: team._id, match: match._id });

  if (isLoading || isFetching) {
    return <Loading />;
  }

  const handleExport = async () => {
    try {
      const url = `/api/team/${team._id}/athletes/stats/match/${match._id}/export`;

      await csvDownloadHandler(url, 'athleteStats.csv');
    } catch (error) {
      console.error('Erro ao exportar CSV:', error);
    }
  };

  const content = (
    <>
      <OffenseTeamMatchStats stats={stats.offense} />
      <DefenseTeamMatchStats stats={stats.defense} />
    </>
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        flex: 1,
        marginBottom: '90px',
      }}
    >
      {content}
      <Box
        sx={{
          width: '100%',
          bottom: '0',
          left: '0',
          padding: '0 10px',
          margin: '0',
          backgroundImage: 'linear-gradient(transparent, white 14%)',
          zIndex: '3',
        }}
      >
        <Button
          className="ignore-unselect-playCard"
          variant="outlined"
          color="primary"
          fullWidth
          onClick={handleExport}
        >
          {t('Exportar estatísticas')}
        </Button>
      </Box>
    </TableContainer>
  );
}

export default MatchStatsTable;
